import "@/scss/style.scss"

import "bootstrap/dist/js/bootstrap.min.js"
import "jquery-viewport-checker/src/jquery.viewportchecker.js"

(function ($, undefined) {
    "use strict";
    let global = {};

    global.init = function () {
        global.menuWidth();
        global.videoMobile();
        global.scrollToSection();
        global.arrowBottomShow();
        global.hamburgerAnimation();
        global.slick();
        global.playStopVideo();
        global.workflowNavArrows();
        global.switchOffNavArrows();
        global.viewPortChecker();
        global.dynamicNumber();
        global.loaders();
        global.cookies();
        global.showFilms();
        AOS.init({
            once: 'true',
            disable: 'mobile',
        });
    };
    global.menuWidth = function () {
        let windowWidth = $(window).width();
        if (windowWidth < 992) {
            $('#menu__Navbar').removeClass('container').addClass('container-fluid');
        }
    };
    global.videoMobile = function () {
        let video = $('#hero');
        let windowWidth = $(window).width();

        if (windowWidth < 992) {
            video.append("<source src='./assets/videos/hero/hero_mobile.mp4' type='video/mp4' >");
        } else {
            video.append("<source src='./assets/videos/hero/hero_compressed.mp4' type='video/mp4' >");
        }
    };



    global.cookies = function () {
        let hasLocalStorage = true;
        setTimeout(function () {
            let cookies = $(".cookies")[0];
            // noinspection EqualityComparisonWithCoercionJS
            if (!hasLocalStorage || localStorage.getItem("cookies") === null || localStorage.getItem("cookies") != 1) {
                cookies.classList.add("active");
                cookies.querySelector(".content-wrapper").classList.add("active")
            }
            $(".btn-accept-cookies")[0].addEventListener("click", function () {
                if (hasLocalStorage) {
                    localStorage.setItem("cookies", "1");
                }
                cookies.querySelector(".content-wrapper").classList.remove("active");
                setTimeout(function () {
                    cookies.classList.remove("active");
                }, 300)
            })
        }, 2000);
    };


    let menuToggle = document.querySelector("#menu-toggle");
    let activeElements = document.querySelectorAll(".active-element");
    let toggledMenu = menuToggle.addEventListener("click", function(){

        for(let activated = 0; activated < activeElements.length; activated++){
            activeElements[activated].classList.toggle("active");
        }
    })

    let navlinkMenu = document.querySelectorAll(".nav-link-menu");
    let activeOne =  document.querySelector(".active-one");
    let menuToggleTwo = document.querySelector("#menu-toggle");
    let afterclick = document.getElementById("after-click")

    navlinkMenu.forEach(
      function (element) {
          element.addEventListener('click', function handleClick() {
              activeOne.classList.remove("active");
              menuToggleTwo.classList.remove("active");
              afterclick.classList.toggle("active");
          });
      },
    );

}(jQuery));
